.App {
  text-align: center;
}

.index-cards-container
{
  display: inline;
}

.header
{
  display: grid;
}

h1
{
  color: white;
}

body
{
  background-color: black;
  font-family: 'Roboto', sans-serif;
}

.telegram {
  margin-bottom: 10px;
  color: white;
}

.telegram-connect-button {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .telegram-connect-button {
    margin-bottom: 40px;
  }
}

.leaderboard-container {
  margin: 50px auto;
  width: 80%;
  max-width: 800px;
}

button {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 600px) {
  .MuiBox-root {
    width: 90% !important; 
    max-width: none !important; 
  }
}
