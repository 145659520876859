.card-logo {
  height: 48px;
  width: 48px;
  margin-left: 8px;
  position: relative;
  margin-top: 2px;
  margin-bottom: 2px;
}

.card {
  border: 1px solid grey;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 382px;
  margin-bottom: 12px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  letter-spacing: normal;
  gap: 20px;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-whitesmoke);
  font-family: var(--font-ibm-plex-sans);
}

.name,
.ticker {
  position: relative;
  display: inline-block;
}

.ticker {
  letter-spacing: 0.02em;
  line-height: 140%;
  font-weight: 500;
  min-width: 65px;
  color: white;
}

.name {
  font-size: var(--font-size-xs);
  line-height: 17px;
  color: #9aa4ae;
  min-width: 69px;
}

.div,
.info {
  display: flex;
  justify-content: flex-start;
}

.div {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-9xs);
}

.info {
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 0;
  gap: 8px;
}

.wrapper-group-2-child {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 4px;
  transform: scale(1.859);
}

.wrapper-group-2 {
  height: 29px;
  width: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.price {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 140%;
  font-weight: 500;
  display: inline-block;
  min-width: 55px;
  white-space: nowrap;
  color: whitesmoke;
}

.arrow-drop-up-black-24dp-1-1 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.change {
  position: relative;
  line-height: 17px;
  font-weight: 500;
  display: inline-block;
  min-width: 37px;
  color: #19af00;
}

.graph,
.market-info {
  display: flex;
  justify-content: flex-start;
}

.graph {
  flex-direction: row;
  align-items: center;
  font-size: var(--font-size-xs);
}

.market-info {
  flex-direction: column;
  align-items: flex-end;
  padding: 0 0 0 3px;
  gap: var(--gap-9xs);
  margin-right: 8px;
}